import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import {useParams} from "react-router-dom";
import FieldServicesDashboard from "../../../components/Dashtail/FieldServicesDashboard";

const ClientDashboard: React.FC = () => {

    const { clientId } = useParams<{ clientId: string }>();

    return (
        <div>
            <Helmet>
                <title>Client Dashboard | Stochastic | Certain Path</title>
            </Helmet>
            <h1>Client Dashboard</h1>
            <FieldServicesDashboard />
        </div>
    );
};

export default ClientDashboard;
