import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product, FetchProductsRequest, FetchProductsResponse } from '../../../api/fetchProducts/types';
import { fetchProducts } from '../../../api/fetchProducts/fetchProductsApi';
import { AppDispatch } from '../../../app/store';

interface ProductsState {
    products: Product[];
    loading: boolean;
    error: string | null;
}

const initialState: ProductsState = {
    products: [],
    loading: false,
    error: null,
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setProducts: (state, action: PayloadAction<Product[]>) => {
            state.products = action.payload;
        },
    },
});

export const { setLoading, setError, setProducts } = productsSlice.actions;

export const fetchProductsAction = (requestData: FetchProductsRequest) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
        const response: FetchProductsResponse = await fetchProducts(requestData);

        if (response.status === 'success') {
            dispatch(setProducts(response.data));
        } else {
            dispatch(setError(response.message));
        }
    } catch (error) {
        if (error instanceof Error) {
            dispatch(setError(error.message));
        } else {
            dispatch(setError('Failed to fetch products'));
        }
    } finally {
        dispatch(setLoading(false));
    }
};

export default productsSlice.reducer;
