import { useEffect } from 'react';

const useSessionRewind = () => {
    useEffect(() => {

        if ((window as any).SessionRewindInitialized) {
            return;
        }

        const sessionRewindConfig = {
            apiKey: 'St6qQghBHr8WIncHXO8Oia3YlEVFVN8o1gHxfe3D',
            startRecording: true,
            // OPTIONAL: Uncomment and modify the following fields as needed
            // createNewSession: false,
            // sessionInfo: { customFieldA: "This is a custom field" },
            // userInfo: { userId: "hello@sessionrewind.com", userName: "John Doe" },
            // onLoad: () => {
            //   window.sessionRewind.getSessionUrl((url) => { console.log(url); });
            // },
        };

        (window as any).SessionRewindConfig = sessionRewindConfig;

        (window as any).SessionRewindInitialized = true;

        const script = document.createElement('script');
        script.async = true;
        script.crossOrigin = 'anonymous';
        script.src = 'https://rec.sessionrewind.com/srloader.js';

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);
};

export default useSessionRewind;
