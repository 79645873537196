import { combineReducers } from "@reduxjs/toolkit";
import datatableReducer from "../components/DataTableStatic/slice";
import authReducer from "../features/Auth/slices/authSlice";
import courseManagementReducer from "../features/CourseManagement/slices/courseManagementSlice";
import clientManagementReducer from "../features/ClientManagement/slices/clientManagementSlice";
import productReducer from "../features/ProductManagement/slices/productSlice";

const rootReducer = combineReducers({
    datatable: datatableReducer,
    auth: authReducer,
    courseManagement: courseManagementReducer,
    clientManagement: clientManagementReducer,
    products: productReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
