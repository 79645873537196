import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet-async";

const CourseRegistration: React.FC = () => {
    return (
        <div>
            <Helmet>
                <title>Course Registration | Training Portal | Certain Path</title>
            </Helmet>

            <div>Course Registration</div>
        </div>
    );
};

export default CourseRegistration;
