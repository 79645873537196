import React, { useEffect, useState, useMemo } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
    SortingState,
    ColumnDef,
    VisibilityState,
    PaginationState,
    Updater
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/catalyst-ui-kit/table';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Client } from '../../api/fetchClients/types';

interface DataTableProps {
    data: Client[];
    columns: ColumnDef<Client, any>[];
    currentPage: number;
    onPageChange: (newPage: number) => void;
    onSortChange: (sortBy: string, sortOrder: 'asc' | 'desc') => void;
    totalCount: number;
    onEdit: (clientId: number) => void;
    onDelete: (clientId: number) => void;
    onUpload: (clientUuid: string) => void;
    onGoToDashboard: (clientId: string) => void;
}

const DataTable: React.FC<DataTableProps> = ({ data, columns, currentPage, onPageChange, onSortChange, totalCount, onEdit, onDelete, onUpload, onGoToDashboard }) => {
    const [sorting, setSorting] = useState<SortingState>([]);

    useEffect(() => {
        if (sorting.length > 0) {
            const { id: sortBy, desc } = sorting[0];
            onSortChange(sortBy, desc ? 'desc' : 'asc');
        }
    }, [sorting, onSortChange]);

    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: currentPage - 1,
        pageSize: 10,
    });

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        columnId1: true,
        columnId2: false,
        columnId3: true,
    });

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            pageIndex: currentPage - 1,
        }));
    }, [currentPage]);

    const handlePaginationChange = (updaterOrValue: Updater<PaginationState>) => {
        if (typeof updaterOrValue === 'function') {
            setPagination((prev) => {
                const newPagination = updaterOrValue(prev);
                onPageChange(newPagination.pageIndex + 1);
                return newPagination;
            });
        } else {
            setPagination(updaterOrValue);
            onPageChange(updaterOrValue.pageIndex + 1);
        }
    };

    const actionColumn: ColumnDef<Client, any> = {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => {
            return (
                <div className="flex space-x-2">
                    <button
                        onClick={() => onEdit(row.original.id)}
                        className="text-blue-600 hover:underline"
                    >
                        Edit
                    </button>
                    <button
                        onClick={() => onDelete(row.original.id)}
                        className="text-red-600 hover:underline"
                    >
                        Delete
                    </button>
                    <button
                        onClick={() => onUpload(row.original.uuid)}
                        className="text-green-600 hover:underline"
                    >
                        Upload
                    </button>
                    <button
                        onClick={() => onGoToDashboard(row.original.uuid)}
                        className="text-purple-600 hover:underline"
                    >
                        Dashboard
                    </button>
                </div>
            );
        },
        enableSorting: false,
    };

    const updatedColumns = useMemo(() => [...columns, actionColumn], [columns]);

    const table = useReactTable({
        data,
        columns: updatedColumns,
        pageCount: Math.ceil(totalCount / pagination.pageSize),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            columnVisibility,
            pagination,
            sorting,
        },
        manualPagination: true,
        manualSorting: true,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: handlePaginationChange,
        onSortingChange: setSorting,
    });

    return (
        <div>
            <Table>
                <TableHead>
                    {table.getAllColumns().map(column => (
                        <label key={column.id}>
                            <input
                                checked={column.getIsVisible()}
                                disabled={!column.getCanHide()}
                                onChange={column.getToggleVisibilityHandler()}
                                type="checkbox"
                            />
                            {column.id}
                        </label>
                    ))}
                    <TableRow>
                        {table.getHeaderGroups().map(headerGroup =>
                            headerGroup.headers.map(header => (
                                <TableHeader
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className='flex mr-2 items-center'>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: <ChevronUpIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400 cursor-pointer" />,
                                            desc: <ChevronDownIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400 cursor-pointer" />,
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                </TableHeader>
                            ))
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map(row => (
                        <TableRow key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <TableCell key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* Pagination Controls */}
            <div className="pagination flex items-center">
                <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                    <ChevronLeftIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400 cursor-pointer" />
                </button>
                <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                    <ChevronRightIcon className="h-5 w-5 text-zinc-500 dark:text-zinc-400 cursor-pointer" />
                </button>
                <span>
                    Page{' '}
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                    </strong>{' '}
                </span>
                <select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default DataTable;
