import React from 'react';
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import logoMain from '../../assets/images/logo-main.svg';
import { NavigationItem } from '../../utils/navigationConfig';

interface MobileSidebarDialogProps {
    sidebarOpen: boolean;
    setSidebarOpen: (open: boolean) => void;
    navigationWide: NavigationItem[];
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

const MobileSidebarDialog: React.FC<MobileSidebarDialogProps> = ({ sidebarOpen, setSidebarOpen, navigationWide }) => (
    <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-40 lg:hidden">
        <DialogBackdrop className="fixed inset-0 bg-gray-900/80 transition-opacity" />
        <div className="fixed inset-0 flex">
            <TransitionChild
                as="div"
                enter="transform transition ease-in-out duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-[5rem]"
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-[5rem]"
                leaveTo="-translate-x-full"
                className="relative flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out translate-x-[5rem] data-[closed]:-translate-x-full"
            >
                <DialogPanel className="relative flex h-full w-full flex-col bg-white shadow-xl">
                    <div className="flex-1 flex flex-col overflow-y-auto bg-white px-6 pb-4">
                        <div className="flex items-center justify-between h-16">
                            <img
                                alt="Your Company"
                                src={logoMain}
                                className="h-8 w-auto"
                            />
                            <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                                <span className="sr-only">Close sidebar</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
                            </button>
                        </div>
                        <nav className="mt-8 flex-1">
                            <ul role="list" className="flex flex-col gap-y-7">
                                {navigationWide.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className={classNames(
                                                item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                            )}
                                        >
                                            <item.icon aria-hidden="true" className={classNames(item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0')} />
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </DialogPanel>
            </TransitionChild>
        </div>
    </Dialog>
);

export default MobileSidebarDialog;
