import React, {useEffect, useState} from 'react';
import MainLayout from "../../components/MainLayout/MainLayout";
import {Outlet} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import DataTable2 from "../../components/DataTableStatic/DataTableStatic";

const TrainingPortalDashboard: React.FC = () => {
    return (
        <div>
            <Helmet>
                <title>Dashboard | Training Portal | Certain Path</title>
            </Helmet>

            <div>Training Portal Dashboard</div>
        </div>
    );
};

export default TrainingPortalDashboard;
