import { HomeIcon, BuildingLibraryIcon, AcademicCapIcon, ReceiptPercentIcon, TrophyIcon, EyeIcon, CubeIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';

export interface NavigationItem {
    name: string;
    href: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    current: boolean;
}

const hubNavigation: NavigationItem[] = [
    { name: 'Hub Dashboard', href: '/hub', icon: HomeIcon, current: false },
    { name: 'Users', href: '/hub/users', icon: HomeIcon, current: false },
    { name: 'Course Management', href: '/hub/courses', icon: HomeIcon, current: false },
];

const trainingPortalNavigation: NavigationItem[] = [
    { name: 'Training Dashboard', href: '/training-portal', icon: HomeIcon, current: false },
    { name: 'Courses', href: '/training-portal/courses', icon: HomeIcon, current: false },
];

const stochasticNavigation: NavigationItem[] = [
    { name: 'Clients', href: '/stochastic/clients', icon: HomeIcon, current: false },
];

const skinnyNavigation: NavigationItem[] = [
    { name: 'Certain Path', href: '/certain-path', icon: CubeIcon, current: false },
    { name: 'Hub', href: '/hub', icon: BuildingLibraryIcon, current: false },
    { name: 'Training Portal', href: '/training-portal', icon: AcademicCapIcon, current: false },
    { name: 'Stochastic', href: '/stochastic', icon: EyeIcon, current: false },
    { name: 'Partner Network', href: '#', icon: ReceiptPercentIcon, current: false },
    { name: 'Scoreboard', href: '#', icon: TrophyIcon, current: false },
];

export function getNavigation(section: string | null, currentPath: string): NavigationItem[] {
    let navigation: NavigationItem[];

    switch (section) {
        case 'hub':
            navigation = hubNavigation;
            break;
        case 'training-portal':
            navigation = trainingPortalNavigation;
            break;
        case 'stochastic':
            navigation = stochasticNavigation;
            break;
        default:
            navigation = [];
            break;
    }

    return navigation.map(item => ({
        ...item,
        current: item.href === currentPath,
    }));
}

export function getSkinnyNavigation(currentPath: string): NavigationItem[] {
    return skinnyNavigation.map(item => ({
        ...item,
        current: currentPath.startsWith(item.href), // Set as current if the current path starts with the item's href
    }));
}

export function getSectionTitle(section: string | null): string {
    switch (section) {
        case 'hub':
            return 'Hub';
        case 'training-portal':
            return 'Training Portal';
        case 'stochastic':
            return 'Stochastic';
        default:
            return 'Certain Path';
    }
}
