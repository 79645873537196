import React from 'react';
import {getSectionTitle, NavigationItem} from '../../utils/navigationConfig';
import logoMain from '../../assets/images/logo-main.svg';

interface WideSidebarProps {
    section: string | null;
    navigation: NavigationItem[];
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

const WideSidebar: React.FC<WideSidebarProps> = ({ section, navigation }) => {

    const sectionTitle = getSectionTitle(section);

    return (
        <div className="hidden lg:flex lg:flex-shrink-0">
            <div className="flex flex-col w-72 bg-white border-r border-gray-200 h-full px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                    <span className="ml-3 text-2xl font-semibold text-gray-900">{sectionTitle}</span>
                </div>
                <nav className="mt-8 flex-1 flex flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-gray-50 text-indigo-600'
                                                    : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                            )}
                                        >
                                            <item.icon
                                                aria-hidden="true"
                                                className={classNames(
                                                    item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                    'h-6 w-6 shrink-0',
                                                )}
                                            />
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default WideSidebar;
