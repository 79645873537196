import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../components/DataTableStatic/slice';
import { Helmet } from 'react-helmet-async';
import { ColumnDef } from '@tanstack/react-table';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import DataTableStatic from "../../components/DataTableStatic/DataTableStatic";

const Users: React.FC = () => {
    const dispatch = useDispatch<any>();
    const data = useSelector((state: any) => state.datatable.data);
    const loading = useSelector((state: any) => state.datatable.loading);
    const error = useSelector((state: any) => state.datatable.error);

    useEffect(() => {
        dispatch(fetchData());
    }, [dispatch]);

    const columns = useMemo<ColumnDef<Record<any, any>, any>[]>(
        () => [
            {
                header: 'First Name',
                accessorKey: 'firstName',
            },
            {
                header: 'Last Name',
                accessorKey: 'lastName',
            },
            {
                header: 'Blood Type',
                accessorKey: 'bloodGroup',
            },
            {
                header: 'Age',
                accessorKey: 'age',
            },
            {
                header: 'Role',
                accessorKey: 'role',
            },
            {
                header: 'Username',
                accessorKey: 'username',
            },
        ],
        []
    );

    return (
        <div>
            <Helmet>
                <title>Users | Hub Plus | Certain Path</title>
            </Helmet>
            {loading ? (
                <LoadingIndicator />
            ) : error ? (
                <div style={{ color: 'red', textAlign: 'center' }}>
                    <p>{error}</p>
                </div>
            ) : (
                <DataTableStatic
                    data={data}
                    columns={columns}
                />
            )}
        </div>
    );
};

export default Users;
