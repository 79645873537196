import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { ColumnDef } from '@tanstack/react-table';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { RootState } from "../../app/rootReducer";
import { fetchClientsAction, setPage, setSorting } from "./slices/clientManagementSlice"; // Import setSorting
import { Client } from "../../api/fetchClients/types";
import DataTable from "../../components/Datatable/Datatable";
import { useHotglue } from '@hotglue/widget';
import { useNavigate } from 'react-router-dom';
import LoginButton from "../../components/LoginButton/LoginButton";

const ClientManagement: React.FC = () => {
    const { openWidget } = useHotglue();
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const clients = useSelector((state: RootState) => state.clientManagement.clients);
    const loading = useSelector((state: RootState) => state.clientManagement.loading);
    const error = useSelector((state: RootState) => state.clientManagement.error);
    const page = useSelector((state: RootState) => state.clientManagement.page);
    const totalCount = useSelector((state: RootState) => state.clientManagement.totalCount);
    const sortBy = useSelector((state: RootState) => state.clientManagement.sortBy);   // Get sortBy from Redux
    const sortOrder = useSelector((state: RootState) => state.clientManagement.sortOrder); // Get sortOrder from Redux

    // Fetch clients on page or sort change
    useEffect(() => {
        dispatch(fetchClientsAction({ page, sortBy, sortOrder }));
    }, [dispatch, page, sortBy, sortOrder]);  // Add sortBy and sortOrder as dependencies

    const handlePageChange = (newPage: number) => {
        dispatch(setPage(newPage));
    };

    const handleSortChange = (sortBy: string, sortOrder: 'asc' | 'desc') => {
        dispatch(setSorting({ sortBy, sortOrder }));
    };

    const columns = useMemo<ColumnDef<Client>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
            },
            {
                header: 'Name',
                accessorKey: 'name',
            },
            // Add more columns as needed
        ],
        []
    );

    const handleUpload = (clientUuid: string) => {
        navigate(`/stochastic/clients/${clientUuid}/connector`);
    };

    const handleGoToDashboard = (clientUuid: string) => {
        navigate(`/stochastic/clients/${clientUuid}/dashboard`);
    };

    const launchHotGlue = (clientId: number) => {
        try {
            if (!clientId) {
                throw new Error("Client ID is undefined or invalid.");
            }
            openWidget(clientId.toString(), {});
        } catch (error) {
            console.error("Error launching Hotglue widget:", error);
        }
    };

    return (
        <div>
            <Helmet>
                <title>Client Management | Stochastic | Certain Path</title>
            </Helmet>
            <h1>Client Management</h1>
            <div>
                <LoginButton/>
            </div>
            {loading ? (
                <LoadingIndicator />
            ) : error ? (
                <div style={{ color: 'red', textAlign: 'center' }}>
                    <p>{error}</p>
                </div>
            ) : (
                <DataTable
                    data={clients}
                    columns={columns}
                    currentPage={page}
                    onPageChange={handlePageChange}
                    onSortChange={handleSortChange}  // Pass sorting handler to DataTable
                    totalCount={totalCount}
                    onEdit={launchHotGlue}
                    onDelete={() => {}}
                    onUpload={handleUpload}
                    onGoToDashboard={handleGoToDashboard}
                />
            )}
        </div>
    );
};

export default ClientManagement;
