import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client, FetchClientsRequest, FetchClientsResponse } from "../../../api/fetchClients/types";
import { fetchClients } from "../../../api/fetchClients/fetchClientsApi";
import { AppDispatch } from "../../../app/store";

interface ClientsState {
    clients: Client[];
    totalCount: number;
    loading: boolean;
    error: string | null;
    page: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
}

const initialState: ClientsState = {
    clients: [],
    totalCount: 0,
    loading: false,
    error: null,
    page: 1,
    sortBy: 'id',
    sortOrder: 'asc',
};

const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setClients: (state, action: PayloadAction<{ clients: Client[], totalCount: number }>) => {
            state.clients = action.payload.clients;
            state.totalCount = action.payload.totalCount;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        setSorting: (state, action: PayloadAction<{ sortBy: string, sortOrder: 'asc' | 'desc' }>) => {
            state.sortBy = action.payload.sortBy;
            state.sortOrder = action.payload.sortOrder;
        },
    },
});

export const { setLoading, setError, setClients, setPage, setSorting } = clientsSlice.actions;

export const fetchClientsAction = (requestData: FetchClientsRequest) => async (dispatch: AppDispatch, getState: () => ClientsState) => {
    dispatch(setLoading(true));
    try {
        const response: FetchClientsResponse = await fetchClients(requestData);

        dispatch(setClients({
            clients: response.data.clients,
            totalCount: response.meta?.totalCount ?? 0
        }));
    } catch (error) {
        if (error instanceof Error) {
            dispatch(setError(error.message));
        } else {
            dispatch(setError('Failed to fetch clients'));
        }
    } finally {
        dispatch(setLoading(false));
    }
};

export default clientsSlice.reducer;
