import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Course, FetchCoursesRequest, FetchCoursesResponse} from "../../../api/fetchCourses/types";
import {fetchCourses} from "../../../api/fetchCourses/fetchCoursesApi";
import {AppDispatch} from "../../../app/store";

interface CoursesState {
    courses: Course[];
    loading: boolean;
    error: string | null;
}

const initialState: CoursesState = {
    courses: [],
    loading: false,
    error: null,
};

const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setCourses: (state, action: PayloadAction<Course[]>) => {
            state.courses = action.payload;
        },
    },
});

export const { setLoading, setError, setCourses } = coursesSlice.actions;

export const fetchCoursesAction = (requestData: FetchCoursesRequest) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
        const response: FetchCoursesResponse = await fetchCourses(requestData);

        if (response.status === 'success') {
            dispatch(setCourses(response.data));
        } else {
            dispatch(setError(response.message));
        }
    } catch (error) {
        if (error instanceof Error) {
            dispatch(setError(error.message));
        } else {
            dispatch(setError('Failed to fetch courses'));
        }
    } finally {
        dispatch(setLoading(false));
    }
};

export default coursesSlice.reducer;
