import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import {useParams} from "react-router-dom";
import HVACDashboard from "../../components/Dashtail/HVACDashboard";

const CoachesDashboard: React.FC = () => {

    return (
        <div>
            <Helmet>
                <title>Coaches Dashboard | Certain Path</title>
            </Helmet>
            <HVACDashboard />
        </div>
    );
};

export default CoachesDashboard;
