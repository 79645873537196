import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/20/solid';
import { breadcrumbNameMap } from '../../utils/breadcrumbConfig';

const Breadcrumbs: React.FC = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <nav aria-label="Breadcrumb" className="flex border-b border-gray-200 bg-white">
            <ol role="list" className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                <li className="flex">
                    <div className="flex items-center">
                        <Link to="/" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                {pathnames.map((value, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathnames.length - 1;
                    return (
                        <li key={to} className="flex">
                            <div className="flex items-center">
                                <svg
                                    fill="currentColor"
                                    viewBox="0 0 24 44"
                                    preserveAspectRatio="none"
                                    aria-hidden="true"
                                    className="h-full w-6 flex-shrink-0 text-gray-200"
                                >
                                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                </svg>
                                {isLast ? (
                                    <span
                                        className="ml-4 text-sm font-medium text-gray-500"
                                        aria-current="page"
                                    >
                                        {breadcrumbNameMap[to] || value}
                                    </span>
                                ) : (
                                    <Link
                                        to={to}
                                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                    >
                                        {breadcrumbNameMap[to] || value}
                                    </Link>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
