import React from 'react';

const LoadingIndicator: React.FC = () => {
    return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
            <div className="spinner"></div>  {/* You can use a CSS spinner or any loading icon here */}
            <p>Loading...</p>
        </div>
    );
};

export default LoadingIndicator;
