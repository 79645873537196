import React from 'react';
import { useLocation } from 'react-router-dom';
import { getSkinnyNavigation, NavigationItem } from '../../utils/navigationConfig';
import logoSmall from '../../assets/images/logo-small.svg';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

const SkinnySidebar: React.FC = () => {
    const location = useLocation();
    const navigationSkinny: NavigationItem[] = getSkinnyNavigation(location.pathname);

    return (
        <div className="flex flex-col w-20 bg-white border-r border-gray-200 h-full z-50">
            <div className="flex h-16 shrink-0 items-center justify-center">
                <img
                    alt="Your Company"
                    src={logoSmall}
                    className="h-8 w-auto"
                />
            </div>
            <nav className="mt-8 flex-1 flex flex-col items-center">
                <ul role="list" className="space-y-1">
                    {navigationSkinny.map((item) => (
                        <li key={item.name}>
                            <a
                                href={item.href}
                                title={item.name}
                                className={classNames(
                                    item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                )}
                            >
                                <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                                <span className="sr-only">{item.name}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default SkinnySidebar;
