import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable2 from '../../components/DataTableStatic/DataTableStatic';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { ColumnDef } from '@tanstack/react-table';
import { Course } from '../../api/fetchCourses/types';
import {RootState} from "../../app/rootReducer";
import {fetchCoursesAction} from "./slices/courseManagementSlice";

const CourseManagement: React.FC = () => {
    const dispatch = useDispatch<any>();
    const courses = useSelector((state: RootState) => state.courseManagement.courses);
    const loading = useSelector((state: RootState) => state.courseManagement.loading);
    const error = useSelector((state: RootState) => state.courseManagement.error);

    useEffect(() => {
        dispatch(fetchCoursesAction({ limit: 10, offset: 0 }));
    }, [dispatch]);

    const columns = useMemo<ColumnDef<Course>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
            },
            {
                header: 'Name',
                accessorKey: 'name',
            },
            // Add more columns as needed
        ],
        []
    );

    return (
        <div>
            <h1>Course Management</h1>
            {loading ? (
                <LoadingIndicator />
            ) : error ? (
                <div style={{ color: 'red', textAlign: 'center' }}>
                    <p>{error}</p>
                </div>
            ) : (
                null
                /*<DataTableStatic
                    data={courses}
                    columns={columns}
                />*/
            )}
        </div>
    );
};

export default CourseManagement;
