import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    token: string | null;
    refreshToken: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: AuthState = {
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
    status: 'idle',
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            localStorage.setItem('token', action.payload);
        },
        setRefreshToken: (state, action: PayloadAction<string>) => {
            state.refreshToken = action.payload;
            localStorage.setItem('refreshToken', action.payload);
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.status = action.payload ? 'loading' : 'idle';
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
            state.status = 'failed';
        },
        setSuccess: (state) => {
            state.status = 'succeeded';
            state.error = null;
        },
        logout: (state) => {
            state.token = null;
            state.refreshToken = null;
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            state.status = 'idle';
            state.error = null;
        },
    },
});

export const { setToken, setRefreshToken, setLoading, setError, setSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
