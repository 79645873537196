import React, { useEffect } from 'react';
import './App.css';
import AppRoutes from './app/AppRoutes';
import useSessionRewind from './hooks/useSessionRewind';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingIndicator from "./components/LoadingIndicator/LoadingIndicator";
import { addAccessTokenInterceptor } from './api/axiosInstance';

function App() {
    useSessionRewind();

    const { isLoading, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        addAccessTokenInterceptor(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    if (isLoading) {
        return null;
    }

    return (
        <AppRoutes />
    );
}

export default App;
