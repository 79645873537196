import React, {useState} from 'react';
import SkinnySidebar from "../SkinnySidebar/SkinnySidebar";
import WideSidebar from "../WideSidebar/WideSidebar";
import TopNavBar from "../TopNavBar/TopNavBar";
import MainContent from "../MainContent/MainContent";
import MobileSidebarDialog from "../MobileSidebarDialog/MobileSidebarDialog";
import {getNavigation} from '../../utils/navigationConfig';
import {Outlet, useLocation} from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Footer2 from "../Footer2/Footer2";

interface CombinedLayoutProps {
    /*children: React.ReactNode;*/
    section: string | null;
}

const MainLayout: React.FC<CombinedLayoutProps> = ({section}) => {
    const [sidebarOpenWide, setSidebarOpenWide] = useState(false);
    const location = useLocation();

    const navigationWide = getNavigation(section, location.pathname);

    return (
        <div className="flex h-screen">
            <SkinnySidebar/>
            <WideSidebar section={section} navigation={navigationWide}/>
            <div className="flex flex-1 flex-col">
                <TopNavBar onSidebarToggle={() => setSidebarOpenWide(true)}/>
                <Breadcrumbs/>
                <MainContent>
                    <Outlet/>
                </MainContent>
                <Footer2/>
            </div>
            <MobileSidebarDialog
                sidebarOpen={sidebarOpenWide}
                setSidebarOpen={setSidebarOpenWide}
                navigationWide={navigationWide}
            />
        </div>
    );
};

export default MainLayout;
