import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HubDashboard from "../features/HubDashboard/HubDashboard";
import Users from "../features/Users/Users";
import CourseManagement from "../features/CourseManagement/CourseManagement";
import TrainingPortalDashboard from "../features/TrainingPortalDashboard/TrainingPortalDashboard";
import CourseRegistration from "../features/CourseRegistration/CourseRegistration";
import MainLayout from "../components/MainLayout/MainLayout";
import ClientManagement from "../features/ClientManagement/ClientManagement";
import ProductManagement from "../features/ProductManagement/ProductManagement";
import ClientConnector from "../features/ClientManagement/ClientConnector/ClientConnector";
import ClientDashboard from "../features/ClientManagement/ClientDashboard/ClientDashboard";
import CoachesDashboard from "../features/CoachesDashboard/CoachesDashboard";
import Login from "../features/Auth/components/Login/Login";
import { AuthenticationGuard } from "../components/AuthenticationGuard/AuthenticationGuard";

const AppRoutes = () => {
    return (
        <Routes>
            {/* Redirect from "/" to "/hub" */}
            <Route path="/" element={<Navigate to="/hub" replace />} />

            <Route path="/certain-path" element={<Navigate to="/certain-path/coaches-dashboard" replace />} />

            {/* Certain Path Routes (Protected) */}
            <Route
                path="/certain-path"
                element={
                    <AuthenticationGuard component={() => <MainLayout section="hub" />} />
                }
            >
                <Route path="coaches-dashboard" element={<CoachesDashboard />} />
            </Route>

            {/* Hub Routes (Protected) */}
            <Route
                path="/hub"
                element={
                    <AuthenticationGuard component={() => <MainLayout section="hub" />} />
                }
            >
                <Route index element={<HubDashboard />} />
                <Route path="courses" element={<CourseManagement />} />
                <Route path="users" element={<Users />} />
                <Route path="products" element={<ProductManagement />} />
            </Route>

            {/* Training Portal Routes (Protected) */}
            <Route
                path="/training-portal"
                element={
                    <AuthenticationGuard component={() => <MainLayout section="training-portal" />} />
                }
            >
                <Route index element={<TrainingPortalDashboard />} />
                <Route path="courses" element={<CourseRegistration />} />
            </Route>

            {/* Stochastic Routes (Protected) */}
            <Route
                path="/stochastic"
                element={
                    <AuthenticationGuard component={() => <MainLayout section="stochastic" />} />
                }
            >
                <Route path="clients" element={<ClientManagement />} />
                <Route path="clients/:clientId/connector" element={<ClientConnector />} />
                <Route path="clients/:clientId/dashboard" element={<ClientDashboard />} />
            </Route>

            {/* Login Route */}
            <Route path="/login" element={<Login />} />
        </Routes>
    );
};

export default AppRoutes;
