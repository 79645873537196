import React, {useEffect, useState} from 'react';
import MainLayout from "../../components/MainLayout/MainLayout";
import {Outlet} from "react-router-dom";
import {Helmet} from "react-helmet-async";

const HubDashboard: React.FC = () => {
    return (
        <div>
            <Helmet>
                <title>Dashboard | Hub Plus | Certain Path</title>
            </Helmet>
            Hub Dashboard
        </div>
    );
};

export default HubDashboard;
