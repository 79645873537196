import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable2 from '../../components/DataTableStatic/DataTableStatic';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { ColumnDef } from '@tanstack/react-table';
import { Product } from '../../api/fetchProducts/types';
import { RootState } from "../../app/rootReducer";
import {fetchProductsAction} from "./slices/productSlice";

const ProductManagement: React.FC = () => {
    const dispatch = useDispatch<any>();
    const products = useSelector((state: RootState) => state.products.products);
    const loading = useSelector((state: RootState) => state.products.loading);
    const error = useSelector((state: RootState) => state.products.error);

    useEffect(() => {
        dispatch(fetchProductsAction({ page: 1 }));
    }, [dispatch]);

    const columns = useMemo<ColumnDef<Product>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
            },
            {
                header: 'Name',
                accessorKey: 'name',
            },
            // Add more columns as needed
        ],
        []
    );

    return (
        <div>
            <h1>Product Management</h1>
            {loading ? (
                <LoadingIndicator />
            ) : error ? (
                <div style={{ color: 'red', textAlign: 'center' }}>
                    <p>{error}</p>
                </div>
            ) : (
                <DataTable2
                    data={products}
                    columns={columns}
                />
            )}
        </div>
    );
};

export default ProductManagement;
