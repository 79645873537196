import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Connections } from '@hotglue/widget';
import {useParams} from "react-router-dom";

const ClientConnector: React.FC = () => {

    const { clientId } = useParams<{ clientId: string }>();

    return (
        <div>
            <Helmet>
                <title>Connector | Stochastic | Certain Path</title>
            </Helmet>
            <h1>Connector</h1>
            <div>
                <Connections tenant={clientId?.toString() || ''}/>
            </div>
        </div>
    );
};

export default ClientConnector;
