import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export const addAccessTokenInterceptor = (getAccessTokenSilently: any) => {
    axiosInstance.interceptors.request.use(async (config) => {
        try {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            });
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        } catch (error) {
            console.error('Error getting access token:', error);
        }

        if (process.env.REACT_APP_API_DEBUG === 'true') {
            const separator = config.url?.includes('?') ? '&' : '?';
            config.url += `${separator}XDEBUG_SESSION_START=PHPSTORM`;
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    });
};

export default axiosInstance;
