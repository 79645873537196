import { createSlice } from '@reduxjs/toolkit';
import { getSampleTableData } from '../../api/fetchSampledata/api';

interface DatatableState {
    value: number;
    data: any[];
    isSorted: boolean;
    loading: boolean;
    error: string | null;
}

const initialState: DatatableState = {
    value: 0,
    data: [],
    isSorted: false,
    loading: false,
    error: null,
};

const slice = createSlice({
    name: 'datatable',
    initialState: initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        },
    },
});

export const { increment, setData, setLoading, setError, clearError } = slice.actions;

export const fetchData = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(clearError());

    try {
        const data = await getSampleTableData();
        dispatch(setData(data.users));
    } catch (error) {
        dispatch(setError('Failed to fetch data'));
    } finally {
        dispatch(setLoading(false));
    }
};

export default slice.reducer;
